import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { Token } from 'app/interfaces/login/login.interfaces';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;
  public token: string;
  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    
    

  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(credenciales: any) {
    return this._http
      .post<any>(`${environment.desarrolloApi}login/logeo`, { option : 3 , value: JSON.stringify(credenciales)})
      .pipe(
        map(user => {

          if(user && user.statusCode == 200){
            let jsonToken : Token = JSON.parse(user.value)
            localStorage.setItem('token', jsonToken.token);
            this.currentUserSubject.next(JSON.parse(user.value));
            
          }

          return user;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('menu');
    localStorage.removeItem('token');
    // notify
    this.currentUserSubject.next(null);
  }

  cargarMenu(json_usu: string) {
    return this._http.
          post<any>(`${environment.desarrolloApi}login/cargar-menus`,{option: 2, value: json_usu, token: localStorage.getItem('token')})
          .pipe(
            map(menu => {
              if(menu && menu.statusCode == 200){
                localStorage.setItem('menu', menu.value);
              }
              return menu;
            })
          );
  }

  cargarUsuario(){
    return this._http.
          post<any>(`${environment.desarrolloApi}login/cargar-user`,{option: 5, value: '', token: localStorage.getItem('token')})
          .pipe(
            map(user => {
              if(user && user.statusCode == 200){
                localStorage.setItem('currentUser', user.value);
              }
              return user;
            })
          );
  }

  validarToken(){
    return this._http.
          post<any>(`${environment.desarrolloApi}login/validartoken`,{option: 4, value: '', token: localStorage.getItem('token')})
          .pipe(
            map(token => {
              if(token.statusCode != 200){
                this.logout();
                return false;
              }
              return true;
            })
          );
  }
}
