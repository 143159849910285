import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';

import { AuthenticationService } from 'app/auth/service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService, private _coreMenuService: CoreMenuService) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this._authenticationService.validarToken().subscribe(tokenAct => {
      if(!tokenAct){
        this._router.navigate(['/pages/authentication/login-v2'], { queryParams: { returnUrl: state.url } });
      }
    }, error => {
      console.log(error);
    })
    const menu = JSON.parse(localStorage.getItem("menu"));
    if (menu) {
      this._coreMenuService.unregister('main');
      this._coreMenuService.register('main', menu);
      this._coreMenuService.setCurrentMenu('main');
      // check if route is restricted by role
      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate(['/pages/authentication/login-v2'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
