<div *ng
If="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="./assets/images/logos-sjt/logo.png" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Bookmark -->
    <app-navbar-bookmark *ngIf="false"></app-navbar-bookmark>
    <!--/ Bookmark -->
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Language selection -->
    <li ngbDropdown class="nav-item dropdown dropdown-language" *ngIf="false">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li>
    <!--/ Language selection -->

    <!-- Toggle skin -->
    <li class="nav-item d-none d-lg-block" *ngIf="false">
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li>
    <!--/ Toggle skin -->

    <!-- Search -->
    <app-navbar-search *ngIf="false" ></app-navbar-search>
    <!--/ Search -->

    <!-- Cart -->
    <app-navbar-cart *ngIf="false" ></app-navbar-cart>
    <!--/ Cart -->

    <!-- Notification -->
    <app-navbar-notification *ngIf="false"></app-navbar-notification>
    <!--/ Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <ng-container *ngIf="this.currentUser">
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder"
              >{{(this.currentUser.nom_aseg + ' ' + this.currentUser.pat_aseg + ' ' + this.currentUser.mat_aseg) | titlecase}}</span
            ><span class="user-status">{{ (this.currentUser.perfil) | titlecase}}</span>
          </div>
          <span class="avatar"
            ><img
              class="round"
              [src]="this.user.genero == 'M' ? 
                './assets/images/avatars/2-small.png' :
                './assets/images/avatars/1-small.png'"
              alt="avatar"
              height="40"
              width="40" /><span class="avatar-status-online"></span
          ></span>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a ngbDropdownItem [routerLink]="['/misDatos']"
          ><span [data-feather]="'user'" [class]="'mr-50'"></span> Perfil</a
        >
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Cerrar Sesión</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
